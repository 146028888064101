export const palette = {
    warning: {
        lighter: "#FFF7CD",
        light: "#FFE16A",
        main: "#FFC107",
        dark: "#B78103",
        darker: "#7A4F01",
        default: "#FFC107",
    },
    success: {
        lighter: "#E9FCD4",
        light: "#AAF27F",
        main: "#54D62C",
        default: "#54D62C",
        dark: "#229A16",
        darker: "#08660D",
    },
    primary: {
        lighter: "#C8FACD",
        light: "#5BE584",
        default: "#005C29",
        main: "#005C29",
        dark: "#007B55",
        darker: "#005249",
    },
    info: {
        lighter: "##D0F2FF",
        light: "#74CAFF",
        main: "#1890FF",
        default: "#1890FF",
        dark: "#0C53B7",
        darker: "#04297A",
    },
    secondary: {
        main: "#ff4fae",
        default: "#ff4fae",
    },
    thirdly: {
        main: "#00814f",
        default: "#00814f",
    },
    error: {
        lighter: "#FFE7D9",
        light: "#FFA48D",
        main: "#FF4842",
        default: "#FF4842",
        dark: "#B72136",
        darker: "#7A0C2E",
    },
    grey: {
        100: "#F9FAFB",
        200: "#F4F6F8",
        300: "#DFE3E8  ",
        400: "#C4CDD5",
        500: "#919EAB",
        600: "#637381",
        700: "#454F5B",
        800: "#212B36",
        900: "#161C24",
        default: "#919EAB",
    },
    background: {
        default: "#fff",
    },
};

export default { palette };
