export const ICON_MOBILE_LOGO = "/images/mobile/logo.png";
export const MOBILE_ICON_CART = "/images/mobile/cart.svg";
export const MOBILE_ICON_DISCOVERY = "/images/mobile/earth.svg";
export const MOBILE_ICON_STORE = "/images/mobile/store.svg";
export const MOBILE_ICON_HOME = "/images/mobile/home.svg";
export const MOBILE_ICON_COUPON = "/images/mobile/coupon.svg";
export const MOBILE_ICON_BACK = "/images/mobile/back.svg";
export const MOBILE_ICON_SEARCH = "/images/mobile/search.svg";
export const MOBILE_ICON_RANK_DIAMOND = "/images/mobile/account/rank/diamond_rank.svg";
export const MOBILE_ICON_RANK_GOLD = "/images/mobile/account/rank/gold_rank.svg";
export const MOBILE_ICON_RANK_PLATIUM = "/images/mobile/account/rank/platium_rank.svg";
export const MOBILE_ICON_RANK_SILVER = "/images/mobile/account/rank/silver_rank.svg";
export const MOBILE_ICON_TS_POINT = "/images/mobile/ts_point.svg";
export const MOBILE_ICON_WALLET = "/images/mobile/wallet.svg";
export const BUSINESS_ICON = "/images/mobile/userinfo/business_icon.svg";
export const CHAT_ICON = "/images/mobile/userinfo/chat_icon.svg";
export const CONTACT_ICON = "/images/mobile/userinfo/contact_icon.svg";
export const M_EYE_ICON = "/images/mobile/userinfo/eye_icon.svg";
export const HAND_SHAKE_ICON = "/images/mobile/userinfo/hand_shake_icon.svg";
export const HEART_ICON = "/images/mobile/userinfo/heart_icon.svg";
export const INSIGHT_ICON = "/images/mobile/userinfo/insight_icon.svg";
export const ORDER_ICON = "/images/mobile/userinfo/order_icon.svg";
export const PHONE_ICON = "/images/mobile/userinfo/phone_icon.svg";
export const POINT_ACCUMULATING_ICON = "/images/mobile/userinfo/point_accumulating_icon.svg";
export const QUESTION_ICON = "/images/mobile/userinfo/question_icon.svg";
export const RESEARCH_INFO_ICON = "/images/mobile/userinfo/research_info_icon.svg";
export const SIGN_OUT_ICON = "/images/mobile/userinfo/sign_out_icon.svg";
export const TRUCK_ICON = "/images/mobile/userinfo/truck_icon.svg";
export const MOBILE_USER_ICON = "/images/mobile/userinfo/user_icon.svg";
export const MOBILE_VOUCHER_ICON = "/images/mobile/userinfo/voucher_icon.svg";
export const MOBILE_ICON_SEARCH_WHITE = "/images/mobile/search_white.svg";

export const ARROW_PRICE_ICON = "/icons/mobile/arrow_price_icon.png";
export const COUPON_ICON = "/icons/mobile/coupon_icon.svg";
export const GIFT_ICON = "/icons/mobile/gift_icon.svg";
export const DAILY_PROMOTION_ICON = "/icons/mobile/home/daily_promotion_icon.svg";
export const EVENT_ICON = "/icons/mobile/home/event_icon.svg";
export const FLASHSALE_ICON = "/icons/mobile/home/flashsale_icon.svg";
export const NEWPRD_ICON = "/icons/mobile/home/newprd_icon.svg";
export const SELLER_ICON = "/icons/mobile/home/seller_icon.svg";
export const INGREDIENT_ICON = "/icons/mobile/ingredient_icon.svg";
export const PRODUCT_ICON = "/icons/mobile/product_icon.svg";
export const STORE_ICON = "/icons/mobile/store_icon.png";
export const ADDITIONAL_ICON = "/icons/mobile/tags/additional_icon.png";
export const ARROW_ICON = "/icons/mobile/tags/arrow_icon.png";
export const DOCUMENT_ICON = "/icons/mobile/tags/document_icon.png";
export const INGRADIENT_ICON = "/icons/mobile/tags/ingradient_icon.png";
export const LIKE_ICON = "/icons/mobile/tags/like_icon.svg";
export const TARGET_ICON = "/icons/mobile/tags/target_icon.png";
export const DOCUMENT_ICON2 = "/icons/mobile/userinfo/document_icon.svg";
export const EYE_ICON = "/icons/mobile/userinfo/eye_icon.svg";
export const INFO_ICON = "/icons/mobile/userinfo/info_icon.svg";
export const LEVEL_ICON = "/icons/mobile/userinfo/level_icon.svg";

export const MOBILE_ICON_SORT = "/icons/mobile/sort.svg";
export const MOBILE_ICON_FILTER = "/icons/mobile/filter.svg";
export const MOBILE_ICON_ALERT_WARNING = "/icons/mobile/alert/warning.svg";
export const MOBILE_ICON_ALERT_SUCCESS = "/icons/mobile/alert/success.svg";
export const MOBILE_ICON_ARROW_DOWN = "/icons/mobile/arrow_down.svg";
export const MOBILE_DOUBLE_ARROW_RIGHT = "/icons/mobile/store/double_arrow_right.svg";
export const MOBILE_ICON_SLIDE_ARROW_RIGHT = "/icons/mobile/store/slider_right_arrow.svg";
export const MOBILE_ICON_SLIDE_ARROW_LEFT = "/icons/mobile/store/slider_left_arrow.svg";
export const MOBILE_ICON_SORT_HOT_SALE = "/icons/mobile/products/sort/hot-sale.svg";
export const MOBILE_ICON_SORT_NEWEST = "/icons/mobile/products/sort/newest.svg";
export const MOBILE_ICON_SORT_PRICE_ASC = "/icons/mobile/products/sort/price-asc.svg";
export const MOBILE_ICON_SORT_PRICE_DESC = "/icons/mobile/products/sort/price-desc.svg";
export const MOBILE_ICON_SORT_NAME_ASC = "/icons/mobile/products/sort/name-asc.svg";
export const MOBILE_ICON_SORT_NAME_DESC = "/icons/mobile/products/sort/name-desc.svg";

export const MOBILE_ICON_DOTS = "/icons/mobile/chat/dot.svg";
export const MOBILE_ICON_AVATAR_CS = "/icons/mobile/chat/avatar_cs.svg";
export const MOBILE_ICON_CHAT = "/icons/mobile/chat/icon_chat.svg";
export const MOBILE_ICON_EMPTY_CHAT = "/icons/mobile/chat/icon_empty_message.svg";
export const MOBILE_ICON_SENT = "/icons/mobile/chat/sent.svg?v=1";
export const MOBILE_ICON_SENDING = "/icons/mobile/chat/sending.svg?v=1";

export const MOBILE_CONVERSATION_ICON = "/static/mobile/icons/icon_chat.svg";
